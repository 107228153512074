.big_box2[data-v-3988d399] {
  width: 1350px;
  padding: 20px 40px;
  background: #fff;
  box-shadow: 0 2px 10px #ddd;
  max-height: 750px;
  overflow-y: auto;
  position: relative;
  border: 1px solid #ddd;
}
.big_box2 .arrow_btn[data-v-3988d399] {
  width: 20px;
  height: 60px;
  text-align: center;
}
.big_box2 .arrow_btn .two_btn[data-v-3988d399] {
  font-size: 20px;
  color: #f15a24;
  line-height: 60px;
  background: #eee;
}
.big_box2 .arrow_btn .arrow_left[data-v-3988d399] {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.big_box2 .arrow_btn .arrow_right[data-v-3988d399] {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.big_box2 .top_title[data-v-3988d399] {
  width: 100%;
  margin: 0 auto;
}
.big_box2 .top_title h5[data-v-3988d399] {
  font-size: 30px;
  color: #333;
  text-align: center;
  font-weight: 500;
  margin-bottom: 36px;
}
.big_box2 .name_box[data-v-3988d399] {
  margin: 0 auto;
  text-align: center;
  font-weight: 400;
  margin-bottom: 10px;
  position: relative;
}
.big_box2 .name_box .hide_sel[data-v-3988d399] {
  overflow: auto;
  width: 260px;
  height: 0;
  background: #fff;
  z-index: 9;
  position: absolute;
  left: 50%;
  transform: translateX(-44%);
  line-height: 30px;
  font-size: 14px;
  color: #333;
  text-align: left;
}
.big_box2 .name_box .hide_sel li[data-v-3988d399] {
  cursor: pointer;
}
.big_box2 .name_box .hide_sel li[data-v-3988d399]:hover {
  color: #008000;
}
.big_box2 .name_box h5[data-v-3988d399] {
  display: inline-block;
  margin: 0 auto;
  font-size: 20px;
  color: #008000;
  text-align: center;
  font-weight: 400;
  border-bottom: 4px double #008000;
  line-height: 40px;
  position: relative;
  padding: 0 28px 0 4px;
  cursor: pointer;
}
.big_box2 .name_box h5 span[data-v-3988d399] {
  position: absolute;
  top: 13px;
  right: 3px;
  width: 0;
  height: 0;
  border-bottom: 16px solid #008000;
  border-left: 16px solid transparent;
  cursor: pointer;
}
.big_box2 .top_input[data-v-3988d399] {
  width: 30%;
}
.big_box2 .top_input h6[data-v-3988d399] {
  float: left;
  width: 60px;
  font-size: 14px;
  color: #333;
  font-weight: normal;
  line-height: 36px;
  margin-right: 10px;
}
.big_box2 .top_input2[data-v-3988d399] {
  width: 30%;
  margin: 0 auto;
  position: relative;
}
.big_box2 .top_input2 h6[data-v-3988d399] {
  float: left;
  width: 60px;
  font-size: 14px;
  color: #333;
  font-weight: normal;
  line-height: 28px;
  margin-right: 10px;
}
.big_box2 .top_input2 input[data-v-3988d399] {
  float: left;
  width: 47%;
  border: none;
  border-bottom: 1px solid #666;
  margin-left: 5px;
  line-height: 22px;
}
.big_box2 .content[data-v-3988d399] {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #008000;
  margin-top: 10px;
  border-right: none;
}
.big_box2 .grid-content[data-v-3988d399] {
  position: relative;
  min-height: 38px;
  font-size: 14px;
  color: #666;
  line-height: 38px;
  text-align: center;
}
.big_box2 .row_box2[data-v-3988d399] {
  width: 100%;
  height: 154px;
}
.big_box2 .height_font[data-v-3988d399] {
  width: 100%;
  height: 152px;
  margin: 0 auto;
  text-align: center;
}
.big_box2 .height_font p[data-v-3988d399] {
  width: 20px;
  margin: 0 auto;
  height: 100%;
  line-height: 28px;
  padding-top: 22px;
}
.big_box2 .list_info[data-v-3988d399] {
  line-height: 24px;
}
.big_box2 .list_info.red li[data-v-3988d399] {
  border-bottom: 1px solid #d56400 !important;
}
.big_box2 .list_info li[data-v-3988d399] {
  position: relative;
  line-height: 38px;
  height: 38px;
  font-size: 14px;
  color: #333;
  border-bottom: 1px solid #008000;
}
.big_box2 .list_info li[data-v-3988d399]:last-child {
  border-bottom: none;
}
.big_box2 .list_info li input[data-v-3988d399] {
  width: 100%;
  margin: 0 auto;
  height: 36px;
  line-height: 36px;
  font-size: 14px;
  color: #333;
  background: none;
  border: none;
  padding: 0 5px;
}
.big_box2 .weight[data-v-3988d399] {
  font-weight: 700;
}
.big_box2 .weight li[data-v-3988d399] {
  font-weight: 600 !important;
}
.big_box2 .star[data-v-3988d399] {
  color: #f15a24;
  height: 12px;
  font-size: 18px;
  display: inline-block;
  z-index: 999;
}
.big_box2 .input_box[data-v-3988d399] {
  width: 100%;
  height: 152px;
  font-size: 14px;
  color: #666;
  text-align: center;
}
.big_box2 .top_font[data-v-3988d399] {
  border-bottom: 1px solid #008000;
  line-height: 38px;
  height: 39px;
  font-size: 14px;
  color: #333;
}
.big_box2 .item_box[data-v-3988d399] {
  width: 100%;
  margin: 0 auto;
  border-top: 1px solid #008000;
  position: relative;
}
.big_box2 .item_box .close[data-v-3988d399] {
  width: 1.4em;
  height: 1.4em;
  vertical-align: -0.15em;
  fill: currentColor;
  position: absolute;
  right: -32px;
  top: 9px;
  font-size: 20px;
  color: #ff1818;
  cursor: pointer;
}
.big_box2 .item_box .close .svg-icon[data-v-3988d399] {
  width: 100%;
  height: 100%;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.big_box2 .item_box .add[data-v-3988d399] {
  width: 1.4em;
  height: 1.4em;
  vertical-align: -0.15em;
  fill: currentColor;
  position: absolute;
  left: -28px;
  top: 9px;
  font-size: 16px;
  color: #f15a24;
  cursor: pointer;
}
.big_box2 .item_box .add .svg-icon[data-v-3988d399] {
  width: 100%;
  height: 100%;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.big_box2 .item_box input[data-v-3988d399] {
  width: 100%;
  margin: 0 auto;
  height: 38px;
  line-height: 38px;
  font-size: 14px;
  color: #333;
  background: none;
  border: none;
  padding: 0 5px;
}
.big_box2 .item_list[data-v-3988d399] {
  width: 100%;
  margin: 0 auto;
  border-top: 1px solid #008000;
  position: relative;
}
.big_box2 .item_list .add[data-v-3988d399] {
  position: absolute;
  right: -38px;
  top: 10px;
  width: 1.8em;
  height: 1.8em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
  color: #f15a24;
  cursor: pointer;
}
.big_box2 .item_list .add .svg-icon[data-v-3988d399] {
  width: 100%;
  height: 100%;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.big_box2 .item_list .bg-purple[data-v-3988d399] {
  line-height: 38px;
  height: 38px;
  font-size: 14px;
  color: #333;
  border-bottom: 1px solid #008000;
}
.big_box2 .item_list .bg-purple[data-v-3988d399]:last-child {
  border-bottom: none;
}
.big_box2 .bg-purple[data-v-3988d399] {
  text-align: center;
}
.big_box2 .bottom_num[data-v-3988d399] {
  width: 20%;
  margin-top: 10px;
  font-size: 14px;
  color: #333;
  font-weight: normal;
  line-height: 36px;
}
.big_box2 .total[data-v-3988d399] {
  width: 100%;
  margin: 0 auto;
  border-top: 1px solid #008000;
  border-bottom: 1px solid #008000;
  line-height: 38px;
  height: 38px;
  font-size: 14px;
  color: #333;
}
.big_box2 .el-row[data-v-3988d399] {
  width: 100%;
}
.big_box2 .el-col[data-v-3988d399] {
  border-right: 1px solid #008000;
}
.big_box2 .special h5[data-v-3988d399] {
  color: #d56400 !important;
  border-bottom: 4px double #d56400 !important;
}
.big_box2 .special h5 span[data-v-3988d399] {
  border-bottom: 16px solid #d56400 !important;
}
.big_box2 .special .el-col[data-v-3988d399] {
  border-right: 1px solid #d56400;
}
.big_box2 .special .content[data-v-3988d399] {
  border: 1px solid #d56400;
  border-right: none;
}
.big_box2 .special .list_info li[data-v-3988d399] {
  border-bottom: 1px solid #d56400;
}
.big_box2 .special .list_info li[data-v-3988d399]:last-child {
  border-bottom: none;
}
.big_box2 .special .textarea[data-v-3988d399] {
  border-bottom: 1px solid #d56400;
}
.big_box2 .special .top_font[data-v-3988d399] {
  border-bottom: 1px solid #d56400;
}
.big_box2 .special .item_list[data-v-3988d399] {
  border-top: 1px solid #d56400;
}
.big_box2 .special .item_box[data-v-3988d399] {
  border-top: 1px solid #d56400;
}
.big_box2 .special .total[data-v-3988d399] {
  border-top: 1px solid #f15a24;
}
.amod_ad[data-v-3988d399] {
  padding: 10px;
  animation: ad 0.3s 0s both;
  border: 1px solid #ccc;
  height: auto !important;
}